@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  --menu-offset: 74px;
  @screen lg {
    --menu-offset: 88px; 
  }
}

@layer utilities {
  .overflow-hidden-left {
    clip-path: inset( -100vw -100vw -100vw 0);
  }
}

/* ShadCN UI */
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
 
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
 
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
 
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
 
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
 
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
 
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
 
    --ring: 0 0% 3.9%;
 
    --radius: 0.5rem;
 
    --chart-1: 12 76% 61%;
 
    --chart-2: 173 58% 39%;
 
    --chart-3: 197 37% 24%;
 
    --chart-4: 43 74% 66%;
 
    --chart-5: 27 87% 67%;
  }
 
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
 
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
 
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
 
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
 
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
 
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
 
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
 
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
 
    --ring: 0 0% 83.1%;
 
    --radius: 0.5rem;
 
    --chart-1: 220 70% 50%;
 
    --chart-2: 160 60% 45%;
 
    --chart-3: 30 80% 55%;
 
    --chart-4: 280 65% 60%;
 
    --chart-5: 340 75% 55%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-brand-black;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
/* /ShadCN UI */

@layer components {
  .pointer-events-all {
    pointer-events: all;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
      display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }

  .main-menu-offset {
    padding-top: var(--menu-offset) !important;
  }

  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }


  .line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .bg-golden {
    background: linear-gradient(to bottom, #D4AF37 0%, #C5A028 100%);
  }

  .text-golden {
    @apply bg-golden;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  #headlessui-portal-root {
    position: relative;
    z-index: 50;
  }
}

@media screen and (min-width: 380px) and (max-width: 640px) {
  .container {
    max-width: 500px;
  }
}

html {
  /* scroll-behavior: smooth; */
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=checkbox] {
  @apply border-2 border-grey-700;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.prose a, .link {
  @apply text-brand no-underline hover:underline;
}

 /* Store locator center card focused  */
.center-card-focused > div {
  @apply shadow-2xl ring-inset ring-4 ring-brand/60;
}

@keyframes bouncing-marker {
  0% {
    transform: translate(0, 0);
  }
  
  50% {
    transform: translate(0, -12px);
  }
  
  100% {
    transform: translate(0, 0);
  }
}

.map-marker-active {
  animation-name: bouncing-marker;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 6;
}

/* Animations */
.animate-slide-from-bottom {
  @apply data-[state=open]:animate-in data-[state=closed]:animate-out  data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-bottom-full data-[state=open]:slide-in-from-bottom-1/2 duration-300;
}

.animate-appear-from-top {

}

.animate-fade {
  @apply data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 duration-300
}

.animate-accordion {
  @apply transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down data-[state=closed]:h-0 overflow-hidden;
}